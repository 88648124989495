@media print {
    .headerShort, .nav, .toolbar, .footer {
        display: none !important;
    }

    .printOnly {
        display: visible;
    }
}

@media not print {
    .printOnly {
        display: none !important;
    }
}
