/**
 * Assumes no margin on page setup
 */

.pageA4Oldlabels {
    /* Distance from left of page to start of label */
    --labelsheet-margin-left: 6mm;
    /* actual width of the label */
    --label-width: 64mm;
    /* actual height of the label */
    --label-height: 38mm;
    /* horizontal space between labels */
    --label-horiz-spacing: 2mm;
    /* Should be Vertical space between bottom of last row of labels and bottom of page */
    --labelsheet-margin-bottom: 10mm; /* Is actually vertical space - 5mm for some reason? */
    /* Vertical space between top of page and top of first row of labels */
    --labelsheet-margin-top: 15mm;
}

.pageA4 {
    --labelsheet-margin-left: 0mm;
    --label-width: 70mm;
    --label-height: 40mm;
    --label-horiz-spacing: 0mm;
     /* These label sheets don't actually have any space at the top but printer
      * seems unable to print from zero. We also seem to be losing a mm or two
      * each row so better to shift everything down a tad.
      */
    --labelsheet-margin-bottom: 4mm;
    --labelsheet-margin-top: 4mm;
}

.pageA4 {
    box-sizing: border-box;
    width: 210mm;
    padding-top: 0;
    padding-bottom: 0mm;
    padding-left: var(--labelsheet-margin-left);
    padding-right: 0mm;
}

.label {
    color: black;

    box-sizing: border-box;
    width: var(--label-width);
    height: var(--label-height);

    /* padding within the label */
    padding-top: 2mm;
    padding-left: 4mm;
    padding-right: 4mm;
    padding-bottom: 1mm;

    margin: 0mm;
    margin-right: var(--label-horiz-spacing);

    display: inline-flex;
    overflow: clip;
}

/*
 * 21 labels per page in 3x7 layout.
 * Need to add page spacing at the top of each block.
 */
/* match 21, 42, 63 - the last of each page */
.label:nth-of-type(21n) {
    margin-bottom: var(--labelsheet-margin-bottom); 
}

/* match 1, 22, 43, 64 - the first of each page */
.label:nth-of-type(21n+1) {
    margin-top: var(--labelsheet-margin-top);
}

.order {
    display: flex;
    flex-direction: column;

    font-family: sans-serif;
    font-size: 6pt;
}

.order p {
    margin: 0;
}

.order span {
    word-wrap: normal;
    padding-right: 0.6em;
}

.order.small span {
}

.order.very.small span {
    font-size: 8.6pt;
}

.order.very.small span.menuItem {
    font-weight: normal;
}

.order .customer {
    font-size: 10pt;
    font-weight: bold;
}

.order .orderType {
    font-size: 10pt;
}

.order .menuItem {
    font-size: 8pt;
    font-weight: bold;
}
.order .school {
    font-size: 10pt;
}

.order .barcode {
    min-height: 2.5em;
}

.order .date {
    font-size: 8pt;
    font-weight: normal;
}

.order .allergies {
    text-decoration: underline;
    font-size: 10pt;
}

@media not print {
    .pageA4 {
        margin-top: 1em;
    }

    .label {
        border: 1px dashed black;
    }

    .label:nth-of-type(21n) {
        border: 1px dashed red;
    }

    /* match 1, 22, 43, 64 - the first of each page */
    .label:nth-of-type(21n+1) {
        border: 1px dashed blue;
    }

    .order.small span {
        color: red;
    }

    .order.very.small span {
        color: purple;
    }
}

