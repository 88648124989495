::-ms-clear {
    display: none;
}

@font-face {
    /* From: https://www.datto.com/fonts/d-din/ */
    font-family: 'D-DIN 1451';
    src: url('./assets/D-DIN.woff2');
}

html {
    height: 100%;
}

body {
    position: relative;
    overflow: scroll;
    margin: 0;
    padding-bottom: 1.5rem;
    min-height: 100%;

    --col-ll-darkgreen: #0a9076;
    --col-ll-lightgreen: #91c11f;

    --col-ll-kiwilight: #a6d388;
    --col-ll-kiwidark: #77b255;
    --col-ll-kiwibrown: #c1694f;

    --col-ll-darkyellow: #664d03;
    --col-ll-yellow: #fff3cd;
}


@media (min-width: 430px) {
    .headerTall {
        width: 100%;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerShort {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1em;
        padding-right: 1em;
    }
}

@media (max-width: 429px) {
    .headerTall {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerTall > img {
        width: 80%;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .headerShort {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1em;
        padding-right: 1em;
    }

    .headerShort > img {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    .headerShort > #logo_syncromesh {
        height: 60%;
        min-height: 50px;
    }
}

.header img {
    background-color: rgba(0, 0, 0, 0);
}

.header h1 {
    color: #dcdb22;
    vertical-align: top;
    margin: 0;
}

h5 {
    color: #646363;
    margin-left: 2rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content:center;
}

.footer p {
    color: rgb(62, 62, 61);
    font-size: 0.8rem;
}

.footer a, .footer a:visited {
    color: rgb(62, 62, 61);
}

.footer a:hover {
    color: rgb(109, 199, 237);
}

.centered {
    width: 100%;
    text-align: center;
}

.dismissible-message {
    margin: 0 auto;
    max-width: 640px;
}

.centered-box {
    margin: 0 auto;
    max-width: 640px;
}

.wide {
    margin: 0 auto;
    min-width: 700px;
    max-width: 1000px;
}

.ml--1 {
    margin-left: -1em;
    z-index: 1;
}

.ml--2 {
    margin-left: -2em;
}

.mw-content {
    max-width: 640px;
}

.is-valid {
    color: green;
}

.is-invalid {
    color: red;
}

.input-shaded {
    background-color: rgb(220, 220, 220) !important;
}

.summary-count {
    font-size: 14pt;
}

.scene-button {
    cursor: pointer;
}

.scene-button:hover {
    filter: brightness(90%);
}

.scene-button:active {
    filter: brightness(130%);
}

.space-above {
    margin-top: 64px;
}

.right-justify {
    margin-right: 0px;
}

.greyTheme {
    background-color: rgb(230,230,230) !important;
}

.login {
    padding: 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login h3 {
    font-family: 'D-DIN 1451', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
}

.login img {
    padding: 1em;
}

.login input {
    width: 24rem;
}

.nav ul {
    display: flex;
    list-style: none;
}

.nav ul li {
    margin-right: 15px;
}

.nav-active {
    font-weight: bold;
}

.nav-link {
    color: #888 !important;
}

.nav-link.active {
    color: rgb(62,62,61) !important;
}

.nav .text-info {
    color: #666 !important;
    text-decoration: none;
}

.nav .text-info:hover {
    text-decoration: underline;
}

.form-element-error {
    color: red;
    position: absolute;
}

.DateTimePicker .react-datepicker__input-container input {
    padding-top: 7px;
    padding-bottom: 7px;
}

/* Remove the top border that bootstrap 5 added */
.table > :not(:first-child) {
    border-top: 0px !important;
}

.table.minimal td {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.table.minimal td:first-child {
    border-left: 0;
    border-right: 1px solid #dee2e6;
}

.table.minimal td:last-child {
    border-left: 1px solid #dee2e6;
    border-right: 0;
}

.table.minimal thead tr:first-child td {
    border-top: 0;
}

/** Navs used by tabs */

.nav-tabs .nav-link {
    font-size: 1rem;
    background: #fff !important;
}

/* Bootstrap overrides */

.card legend {
    width:inherit; /* Or auto */
    padding:0 10px; /* To give a bit of padding on the left and right */
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: rgb(109, 199, 237) !important;
    background-color: rgb(109, 199, 237) !important;
}

.modal-header {
    background-color: var(--col-ll-kiwilight) !important;
}

@media (min-width: 540px) {
    .modal-dialog {
        min-width: 540px !important;
    }
}

@media (max-width: 539px) {
    .modal-dialog {
        margin: 0 !important;
        margin-top: 3.5rem !important;
    }
}

.modal-shadow .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.30) !important
}

.badge {
    padding: 0.25em 0.4em;
}

.alert-info {
    background-color: rgb(109, 199, 237) !important;
    border-color: rgb(109, 199, 237) !important;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
}

.hover-bright:hover {
    background-color: #aaa !important;
}

:root {
    --bs-info-rgb: 109,199,237;
    --bs-success-rgb: 40,167,69;
}
/*
.bg-info {
    background-color: rgb(109, 199, 237) !important;
    color: #ffe !important;
}
*/

.alert-success{
    background-color: var(--col-ll-kiwilight) !important;
}

.badge.bg-info {
    background-color: var(--col-ll-kiwilight) !important;
    color: #ffe !important;
}

.btn-info {
    background-color: var(--col-ll-kiwidark) !important;
    border-color: var(--col-ll-kiwidark) !important;
    color: #fff !important;
}

.switch .form-switch {
    border-color: var(--col-ll-kiwidark) !important;
    color: #666 !important;
}

.switch .form-check-input:checked {
    border-color: var(--col-ll-kiwidark) !important;
    background-color: var(--col-ll-kiwidark) !important;
}

.switch .form-check-input:focus {
    color: var(--col-ll-kiwidark) !important;
    outline: 0;
    border-color: var(--col-ll-kiwidark) !important;
    box-shadow: none !important;
}

.switch .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
}

.btn-success {
    border-color: #28a745 !important;
    background-color: #28a745 !important;
    color: #fff !important;
}

.btn-success.focus, .btn-success:focus, .btn-success:hover {
    color: #1e7e34 !important;
    background-color: #218838 !important;
    color: #fff !important;
}


.btn-outline-grey {
    color: rgb(62, 62, 61)!important;
    border-color: rgb(197, 197, 197)!important;
}
.btn-outline-grey:hover {
    color: rgb(62, 62, 61) !important;
    background-color: #fff !important;
    border-color: #c5c5c5 !important;
}

.btn-outline-grey:focus, .btn-outline-grey.focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important;
}

/* Override bootstrap listgroup light styles */
.list-group-item-light.list-group-item-action.active {
    background-color: #eee;
    border-left: 3px solid #d33682;
    border-width: 3px !important;
    color: #636464;
    height: 3rem;
}

.list-group-item-light {
    border: none;
    border-left: 3px solid rgba(0,0,0,0);
    border-width: 3px !important;
    height: 3rem;
}

/** A button style that can be applied for hover */
.hover-grey:hover {
    background: #ccc !important;
    color: red !important;
}

.fadeOutKFRowBg {
    animation-duration: 10s;
    animation-name: fadeOutKFRowBg;
    animation-iteration-count: 1;
}

@keyframes fadeOutKFRowBg {
    from {
        background-color: rgb(159, 218, 243);
        border: 2px solid rgb(159, 218, 243);
    }

    50% {
        background-color: rgb(159, 218, 243);
        border: 2px solid rgb(159, 218, 243);
    }

    to {
        background-color: white;
        border: 2px solid white;
    }
}

.logline {
    display: inline;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem;
}

/**** For DataGrid *********************/ 

.filter-cell {
    line-height: 35px;
}

.filter-cell-light {
    line-height: 35px;
    background-color: white;
    border-inline-end: none;
}

/* Checkboxes are not centering - they are getting display set as inline-block 
 * which is throwing things off. this is hopefully a temporary fix */
.checkbox-cell > label {
    display: flex;
}

/* This is just for more plain table headers */

.noborder-cell {
    border-inline-end: none;
    background-color: white !important;
    box-shadow: none;
    color: #646363;
}

/*** For EventLogDataGrid ************************************/ 
.tr-deleting td {
    background: red !important;
    opacity: 0.1;
    transition-duration: .1s;
}

table {
    transition-duration: .1s;
}

/***************************************/ 

.react-datepicker__input-container input {
    border-radius: 4px;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 38px;
}

.react-datepicker-ignore-onclickoutside {
    border-color: red;
}
